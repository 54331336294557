import React from 'react';

import { slider } from './section-clients.module.scss';
import { breakpoints } from '../../config/breakpoints';
import { IClientOpinion } from '../../models/client-opinion.model';
import { ITitleProps } from '../atoms/title';
import { getColorInSequence } from '../../utils/get-color-in-sequence';

import Section from '../hoc/section';
import ClientOpinionCard from '../molecules/client-opinion-card';
import Slider from '../hoc/slider';

interface ISectionClientsProps {
    className?: string;
    clientOpinions: IClientOpinion[];
    titleProps?: ITitleProps;
}

const SectionClients: React.FC<ISectionClientsProps> = ({
    className = '',
    clientOpinions,
    titleProps,
}) => {
    if (clientOpinions.length === 0) return null;

    return (
        <Section
            className={className}
            theme="light"
            contentDisplay="center"
            circleDisplay="none"
            titleProps={titleProps}
            height="auto"
        >
            <Slider
                className={slider}
                showArrows={true}
                spaceBetween={30}
                slidesPerView={1}
                autoplay={{ delay: 5000 }}
                autoHeight={true}
                rewind={true}
                breakpoints={{
                    [breakpoints.iPad]: {
                        slidesPerView: 3,
                    },
                    [breakpoints.phone]: {
                        slidesPerView: 2,
                    },
                }}
            >
                {clientOpinions.map((clientOpinion, index) => {
                    return (
                        <ClientOpinionCard
                            key={`client-opinion-card-${clientOpinion.clientOpinionId}`}
                            client={clientOpinion}
                            color={getColorInSequence(index)}
                        />
                    );
                })}
            </Slider>
        </Section>
    );
};

export default SectionClients;
