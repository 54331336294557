// extracted by mini-css-extract-plugin
export var breadcrumbsBox = "city-module--breadcrumbs-box--ZrwS9";
export var challengesContent = "city-module--challenges-content--g+-ji";
export var hero = "city-module--hero--ln9d0";
export var heroContent = "city-module--hero-content--FysxH";
export var heroContentBox = "city-module--hero-content-box--fgoxM";
export var introContent = "city-module--intro-content--gA81M";
export var layout = "city-module--layout--MZxX+";
export var markdown = "city-module--markdown--zyRSD";
export var stepContent = "city-module--step-content--N-h-v";
export var stepItem = "city-module--step-item--V1pCF";
export var stepNumber = "city-module--step-number--1EAYb";
export var stepTitle = "city-module--step-title--uOPem";
export var technologyText = "city-module--technology-text--NwT8L";
export var technologyTitle = "city-module--technology-title--fYqs4";
export var whyContent = "city-module--why-content--w7hig";